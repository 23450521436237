<template>
  <div class="row">
    <div class="flex xs12">
      <va-card :title="$t('themes.edit')">
        <actions
          slot="actions"
          crud-links="themes"
          :actions="actions"
          :action-data="{id: $route.params.id}"
        />
        <data-form
          :theme="theme"
          :loading="loading"
          @submit="submit"
        ></data-form>
      </va-card>
    </div>
  </div>
</template>

<script>
const Actions = () => import(/* webpackPrefetch: true */ '@/components/extras/Actions')
const DataForm = () => import(/* webpackPrefetch: true */ './Form')

export default {
  name: 'themes-edit',
  components: {
    Actions,
    DataForm,
  },
  data () {
    return {
      headers: { 'Content-Type': 'multipart/form-data' },
      error: false,
      loading: false,
      actions: ['index', 'new', 'view', 'delete'],
      theme: { active: false },
    }
  },
  created () {
    this.updateData()
  },
  methods: {
    // FIXME
    routeBuilder (id) {
      return `themes/${id}`
    },
    async updateData () {
      this.loading = true
      this.error = false

      let u = false
      const themeId = this.$route.params.id
      try {
        u = await this.$http.get(this.routeBuilder(themeId))
      } catch (err) {
        // console.log('Error fetching theme data', err)
        this.error = true
        this.loading = false
        return
      }

      this.loading = false
      this.theme = u.data.data
    },
    async submit (theme) {
      this.loading = true
      theme.append('_method', 'PUT')

      try {
        await this.$http.post(this.routeBuilder(theme.get('id')), theme, { headers: this.headers })
      } catch (e) {
        // console.log('Error while updating theme', e)
        this.loading = false
        return
      }

      this.showToast(this.$t('notifications.alerts.success'), {
        icon: 'fa-check',
        position: 'top-right',
      })
      this.loading = false
      if (theme.get('active') !== '1') return

      let t = false
      try {
        t = await this.$http.get('themes/active')
      } catch (e) {
        // console.log('Error', e)
        return
      }

      this.$store.commit('updateTheme', t.data)
      this.$store.commit('patchTheme')
    },
  },
}
</script>
